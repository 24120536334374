import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBookOpen, faBell, faFileExport } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/admin-sidebar.css';
import logoCloud from '../assets/img/logo-cloud.png';
import dietLogo from '../assets/img/logo-cel.png';

import { getReport } from '../actions/app.action';

function AdminSidebarComponent(props) {
    return <div className="admin-sidebar-wrapper h-100">
        <div className="sidebar-logo d-flex">
            <div className="sidebar-text w-75 pl-2 justify-content-start d-flex align-items-center">
                {/* <h5>TWÓJCEL.PL</h5> */}
            </div>
            <div className="w-50  pr-3 justify-content-center d-flex align-items-center">
                <img alt="app-logo" className="img-fluid" src={dietLogo} />
            </div>

        </div>
        <div className="nav-menu mt-4">
            <div className="d-flex menu-row">
                <Link className="d-flex" to="/users">
                    <div className="justify-content-center align-items-center d-flex menu-row-icon"><FontAwesomeIcon icon={faUsers} style={{ fontSize: '24px' }} color="#68bb73" /></div>
                    <div className="justify-content-start align-items-center d-flex flex-fill menu-row-name">Użytkownicy</div>
                </Link>
            </div>
            <div className="d-flex menu-row">
                <Link className="d-flex" to="/diaries">
                    <div className="justify-content-center align-items-center d-flex menu-row-icon"><FontAwesomeIcon icon={faBookOpen} style={{ fontSize: '24px' }} color="#68bb73" /></div>
                    <div className="justify-content-start align-items-center d-flex flex-fill menu-row-name">Dzienniki</div>
                </Link>
            </div>
            <div className="d-flex menu-row">
                <Link className="d-flex" to="/notifications">
                    <div className="justify-content-center align-items-center d-flex menu-row-icon"><FontAwesomeIcon icon={faBell} style={{ fontSize: '24px' }} color="#68bb73" /></div>
                    <div className="justify-content-start align-items-center d-flex flex-fill menu-row-name">Powiadomienia</div>
                </Link>
            </div>
            <div className="d-flex menu-row" onClick={() => props.getReport()}>
                <div className="justify-content-center align-items-center d-flex menu-row-icon"><FontAwesomeIcon icon={faFileExport} style={{ fontSize: '22px' }} color="#68bb73" /></div>
                <div className="justify-content-start align-items-center d-flex flex-fill menu-row-name">Eksportuj dane</div>
            </div>
        </div>
        <div className="powered-by-sidebar">
            <a href="https://over-cloud.pl" target="_blank">
                <img alt="Powered By Over Cloud" height="40px" src={logoCloud} />
            </a>
        </div>
    </div>

}

const mapDispatchToProps = dispatch => {
    return {
        getReport: () => dispatch(getReport())
    }
};

export default connect(null, mapDispatchToProps)(AdminSidebarComponent);