import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import Select from 'react-select';
import AppLoader from '../../application/components/loader.component';
import DateTimePicker from 'react-datetime-picker';
import { useHistory, useParams } from "react-router-dom";
import _ from 'lodash';

import * as Yup from 'yup';

import '../assets/css/notification.css';


function NotificationComponent(props) {

    let { id } = useParams();
    let history = useHistory();

    let locationState = useLocation().state;

    const { getNotification, clearNotification, notification, users, isLoading } = props;

    const [initialValues, setInitialValues] = useState({
        title: '',
        description: '',
        users: [],
        startDate: new Date(),
        endDate: new Date(),
        intervalType: '',
        repeat: false,
        intervalPeriod: '',
        active: true,
        allUsers: false
    });

    const findSelectedUser = (id) => {
        if (users) {
            const user = _.find(users, (o) => { return o.value === id });
            return user;
        }
    }

    useEffect(() => {
        setInitialValues({
            users: (locationState && locationState.userId) ? [findSelectedUser(locationState.userId)] : [],
        })
    }, [users])

    useEffect(() => {
        if (id) {
            getNotification(id);
        }
        return () => {
            clearNotification();
        }
    }, [id, getNotification, clearNotification]);

    const formData = useCallback(setFormData, [notification, users]);
    useEffect(() => { // jak przyjdzie notifikacja w propsach to ustawiamy go na formularzu
        formData();
    }, [notification, formData]);

    function setFormData() {
        Object.keys(notification).map((name) => {
            if (name === 'intervalType' && notification['intervalType']) {
                formik.setFieldValue('repeat', true);
            }
            if (name === 'users') {
                if (users.length > 0) {
                    formik.setFieldValue(name, users.filter(user => {
                        return notification[name].indexOf(user.value) >= 0;
                    }));
                }
            } else if (name === 'startDate') {
                formik.setFieldValue(name, new Date(notification[name]));
            } else if (name === 'endDate') {
                formik.setFieldValue(name, new Date(notification[name]));
            } else {
                formik.setFieldValue(name, notification[name]);
            }
            return true;
        });
    }

    function handleIsInterval(isInterval) {
        if (!isInterval) {
            formik.setFieldValue('endDate', '');
            formik.setFieldValue('intervalType', '');
            formik.setFieldValue('intervalPeriod', '');
        }
        formik.setFieldValue('repeat', isInterval);
    }

    function handleDelete() {
        const { deleteNotification } = props;
        let res = window.confirm("Czy chcesz usunąć powiadomienie?");
        if (res == true) {
            deleteNotification(id).then(() => {
                history.push('/notifications');
            });
        } else {
            return;
        }
    }

    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: initialValues,

        validationSchema: Yup.object({
            title: Yup.string().required("Wartość jest wymagana"),
            description: Yup.string().required("Wartość jest wymagana"),
            users: Yup.array().when('allUsers', {
                is: false,
                then: Yup.array().required("Wartość jest wymagana"),
                otherwise: Yup.array()
            }),
            repeat: Yup.boolean(),
            startDate: Yup.date().required("Wartość jest wymagana"),
            endDate: Yup.date().when('repeat', {
                is: true,
                then: Yup.date().required("Wartość jest wymagana"),
                otherwise: Yup.date()
            }),
            intervalType: Yup.string().when('repeat', {
                is: true,
                then: Yup.string().required("Wartość jest wymagana").oneOf(["hour", "day", "month"], 'Wartość jest wymagana'),
                otherwise: Yup.string()
            }),
            intervalPeriod: Yup.number().when('repeat', {
                is: true,
                then: Yup.number().required("Wartość jest wymagana"),
                otherwise: Yup.number()
            }),
            active: Yup.string(),

        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            props.saveNotification(values).then(() => {
                history.push('/notifications');
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });
    
    return <div className="notification-wrapper flex-column d-flex flex-fill">
        {isLoading && <AppLoader type="absolute" />}
        <form onSubmit={formik.handleSubmit}>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>{id ? "Edycja" : "Dodawanie"} powiadomienia</h4>
                <div className="save-button ">
                    <button type="submit" className="btn btn-primary">ZAPISZ</button>
                    {id && <button type="button" onClick={() => handleDelete()} className="btn btn-danger ml-2">USUŃ</button>}
                </div>
            </div>
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="title">Tytuł</label>
                            <input
                                autoComplete="new-password"
                                type="text"
                                className={`form-control ${(formik.errors && formik.errors.title) || (errors && errors.title) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.title}
                                name="title"
                                id="title"
                            />
                            {formik.errors.title ? (
                                <div className="error-warpper">{formik.errors.title}</div>
                            ) : null}
                            {errors && errors.title ? (
                                <div className="error-warpper">{errors.title}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="description">Opis</label>
                            <textarea
                                autoComplete="new-password"
                                className={`form-control ${(formik.errors && formik.errors.description) || (errors && errors.description) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                name="description"
                                id="description"
                                rows="5">
                            </textarea>
                            {formik.errors.description ? (
                                <div className="error-warpper">{formik.errors.description}</div>
                            ) : null}
                            {errors && errors.description ? (
                                <div className="error-warpper">{errors.description}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Do wszystkich użytkowników</label>
                            <div className="d-flex flex-row">
                                <div className="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="allUsers-true"
                                        value='true'
                                        onChange={() => { formik.setFieldValue("allUsers", true); formik.setFieldValue('users', []) }}
                                        checked={formik.values.allUsers === 'true' || formik.values.allUsers === true}
                                        name="allUsers"
                                    />
                                    <label className="custom-control-label" htmlFor="allUsers-true">Tak</label>
                                </div>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="allUsers-false"
                                        value='false'
                                        onChange={() => formik.setFieldValue("allUsers", false)}
                                        checked={formik.values.allUsers === 'false' || formik.values.allUsers === false}
                                        name="allUsers"
                                    />
                                    <label className="custom-control-label" htmlFor="allUsers-false">Nie</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!formik.values.allUsers && <div className="row">
                        <div className="form-group col-md-12 col-lg-6 mb-4">
                            <label htmlFor="description">Użytkownicy</label>
                            <Select
                                isMulti
                                placeholder="Wybierz użytkowników"
                                name="users"
                                id="users"
                                value={formik.values.users}
                                onChange={(selectedUser) => formik.setFieldValue("users", selectedUser && selectedUser.length > 0 ? selectedUser : [])}
                                options={users}
                                className={`basic-multi-select ${(formik.errors && formik.errors.users) || (errors && errors.users) ? 'is-error' : ''}`}
                                classNamePrefix="select"
                            />
                            {formik.errors.users ? (
                                <div className="error-warpper">{formik.errors.users}</div>
                            ) : null}
                            {errors && errors.users ? (
                                <div className="error-warpper">{errors.users}</div>
                            ) : null}
                        </div>
                    </div>}
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6 mb-0">
                            <label htmlFor="login">Aktywny</label>
                            <div className="d-flex flex-row">
                                <div className="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="active-true"
                                        value='true'
                                        onChange={() => formik.setFieldValue("active", true)}
                                        checked={formik.values.active === 'true' || formik.values.active === true}
                                        name="active"
                                    />
                                    <label className="custom-control-label" htmlFor="active-true">Tak</label>
                                </div>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="active-false"
                                        value='false'
                                        onChange={() => formik.setFieldValue("active", false)}
                                        checked={formik.values.active === 'false' || formik.values.active === false}
                                        name="active"
                                    />
                                    <label className="custom-control-label" htmlFor="active-false">Nie</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3 col-lg-6">
                            <div className="form-group custom-control custom-checkbox mt-1 mb-0">
                                <input
                                    id="isInterval"
                                    className="custom-control-input"
                                    name="isInterval"
                                    type="checkbox"
                                    onChange={() => handleIsInterval(!formik.values.repeat)}
                                    checked={formik.values.repeat}
                                    value={formik.values.repeat}
                                />
                                <label className="custom-control-label" htmlFor="isInterval">Powiadomienie powtarzalne</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3 col-lg-6">
                            <label htmlFor="description">{`${formik.values.repeat ? "Początek daty cyklicznego " : "Data "}powiadomienia`}</label>
                            <div className="row pl-3 lp24 pt-2 mb-2">
                                <DateTimePicker
                                    name="startDate"
                                    id="startDate"
                                    onChange={(value) => formik.setFieldValue("startDate", value)}
                                    value={formik.values.startDate}
                                />
                            </div>
                            {formik.errors.startDate ? (
                                <div className="error-warpper">{formik.errors.startDate}</div>
                            ) : null}
                            {errors && errors.startDate ? (
                                <div className="error-warpper">{errors.startDate}</div>
                            ) : null}
                        </div>
                    </div>
                    {formik.values.repeat && <div className="row">
                        <div className="col-md-12 pt-3 pb-3 col-lg-6">
                            <label htmlFor="description">{`Koniec daty ${formik.values.repeat ? "cyklicznego " : ""}powiadomienia`}</label>
                            <div className="row pl-3 lp24 pt-2 mb-2">
                                <DateTimePicker
                                    name="endDate"
                                    id="endDate"
                                    onChange={(value) => formik.setFieldValue("endDate", value)}
                                    value={formik.values.endDate}
                                />
                            </div>
                            {formik.errors.endDate ? (
                                <div className="error-warpper">{formik.errors.endDate}</div>
                            ) : null}
                            {errors && errors.endDate ? (
                                <div className="error-warpper">{errors.endDate}</div>
                            ) : null}
                        </div>
                    </div>}
                    {formik.values.repeat && <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Powtarzaj co</label>
                            <div className="d-flex flex-row">
                                <div className="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="hour"
                                        value="hour"
                                        onChange={formik.handleChange}
                                        checked={formik.values.intervalType === 'hour'}
                                        name="intervalType"
                                    />
                                    <label className="custom-control-label" htmlFor="hour">ilość godzin</label>
                                </div>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="day"
                                        value="day"
                                        onChange={formik.handleChange}
                                        checked={formik.values.intervalType === 'day'}
                                        name="intervalType"
                                    />
                                    <label className="custom-control-label" htmlFor="day">ilość dni</label>
                                </div>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="month"
                                        value="month"
                                        onChange={formik.handleChange}
                                        checked={formik.values.intervalType === 'month'}
                                        name="intervalType"
                                    />
                                    <label className="custom-control-label" htmlFor="month">ilość miesięcy</label>
                                </div>
                            </div>
                            {formik.errors.intervalType ? (
                                <div className="error-warpper mt-0 mb-0">{formik.errors.intervalType}</div>
                            ) : null}
                            {errors && errors.intervalType ? (
                                <div className="error-warpper mt-0 mb-0">{errors.intervalType}</div>
                            ) : null}
                        </div>
                    </div>}
                    {formik.values.repeat && <div className="row">
                        <div className="form-group col-md-12 col-lg-2">
                            <label htmlFor="description">Ilość powtarzania</label>
                            <input
                                autoComplete="new-password"
                                type="number"
                                className={`form-control ${(formik.errors && formik.errors.intervalPeriod) || (errors && errors.intervalPeriod) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.intervalPeriod}
                                name="intervalPeriod"
                                id="intervalPeriod"
                            />
                            {formik.errors.intervalPeriod ? (
                                <div className="error-warpper mt-0 mb-0">{formik.errors.intervalPeriod}</div>
                            ) : null}
                            {errors && errors.intervalPeriod ? (
                                <div className="error-warpper mt-0 mb-0">{errors.intervalPeriod}</div>
                            ) : null}
                        </div>
                    </div>}
                </div>
            </div>
        </form>
    </div >
}

export default NotificationComponent;