import React from 'react';
import RestartPasswordComponent from '../components/restartPassword.component';

import { connect } from 'react-redux'
import { restartPassword } from '../actions/app.action';

function RestarPasswordContainer(props) {
    return (
        <RestartPasswordComponent {...props} />
    );
}

const mapStateToProps = (state) => ({
    isAuth: state.AppReducer.isAuth
});

const mapDispatchToProps = (dispatch) => {
    return {
        restartPassword: (email) => restartPassword(email, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RestarPasswordContainer);
