import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import logoCloud from '../assets/img/logo-cloud.png';
import '../assets/css/login.css';

function RestartPasswordComponent(props) {

  const { isAuth } = props;
  let history = useHistory();

  useEffect(() => {
    if (isAuth) {
      history.push('/users');
    }
  }, [isAuth, history])

  const RestartPsswordForm = (props) => {

    const [errors, setApiErrors] = useState(null);
    const [isReset, setIsReset] = useState(false);

    const formik = useFormik({
      initialValues: { login: '' },
      validationSchema: Yup.object({
        login: Yup.string().required("Wartość jest wymagana")
      }),
      onSubmit: (values) => {
        props.restartPassword(values).then(() => {
          setIsReset(true);
        }, (errors) => {
          setApiErrors(errors);
          formik.setSubmitting(false)
        });
      }
    });

    return (<form onSubmit={formik.handleSubmit}>
      <h3>RESTART HASŁA</h3>
      {isReset && <div className="form-group new-pass">
        <span className="new-password-info">Nowe hasło zostało wysłane na maila, przejdź dalej i zaloguj się</span>
      </div>}
      {!isReset && <div className="form-group">
        <label htmlFor="login">E-mail</label>
        <input
          type="login"
          name="login"
          onChange={formik.handleChange}
          value={formik.values.login}
          className={`form-control ${(formik.errors && formik.touched.login && formik.errors.login) || (errors && errors.login) ? 'is-error' : ''}`}
          id="login"
        />
        {formik.errors.login && formik.touched.login ? (
          <div className="error-warpper">{formik.errors.login}</div>
        ) : null}
        {errors && errors.login ? (
          <div className="error-warpper">{errors.login}</div>
        ) : null}
      </div>}
      <div className="form-group">
        {isReset ?
          <button type="button" className="restart-button btn btn-primary">
            <Link className="d-flex" to={'/login'}>
              <span>ZALOGUJ SIĘ</span>
            </Link>
          </button>
          :
          <button type="submit" disabled={formik.isSubmitting} className="login-button btn btn-primary">RESTARTUJ</button>}
      </div>
    </form>);
  };

  return <div className="container-fluid login-form-wrapper d-flex justify-content-center align-items-center">
    <div className="login-form">
      <RestartPsswordForm {...props} />
    </div>
    <div className="powered-by">
      <a href="https://over-cloud.pl" target="_blank">
        <img alt="Powered By Over Cloud" className="img-fluid" src={logoCloud} />
      </a>
    </div>
  </div>
}

export default RestartPasswordComponent;
