import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import logoCloud from '../assets/img/logo-cloud.png';
import '../assets/css/login.css';

function LoginComponent(props) {

  const { isAuth } = props;
  let history = useHistory();

  useEffect(() => {
    if (isAuth) {
      history.push('/users');
    }
  }, [isAuth, history])

  const LoginForm = () => {

    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
      initialValues: { login: '', password: '' },
      validationSchema: Yup.object({
        login: Yup.string().required("Wartość jest wymagana"),
        password: Yup.string().required("Wartość jest wymagana")
      }),
      onSubmit: (values) => {
        props.loginIn(values).then(() => {
          history.push('/users');
        }, (errors) => {
          setApiErrors(errors);
          formik.setSubmitting(false)
        });
      }
    });

    return (<form onSubmit={formik.handleSubmit}>
      <h3>LOGOWANIE</h3>
      <div className="form-group">
        <label htmlFor="login">login</label>
        <input
          type="login"
          name="login"
          onChange={formik.handleChange}
          value={formik.values.login}
          className={`form-control ${(formik.errors && formik.touched.login && formik.errors.login) || (errors && errors.login) ? 'is-error' : ''}`}
          id="login"
        />
        {formik.errors.login && formik.touched.login ? (
          <div className="error-warpper">{formik.errors.login}</div>
        ) : null}
        {errors && errors.login ? (
          <div className="error-warpper">{errors.login}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="password">hasło</label>
        <input
          autoComplete="new-password"
          type="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className={`form-control ${(formik.errors && formik.touched.password && formik.errors.password) || (errors && errors.password) ? 'is-error' : ''}`}
          id="password"
        />
        {formik.errors.password && formik.touched.password ? (
          <div className="error-warpper">{formik.errors.password}</div>
        ) : null}
        {errors && errors.password ? (
          <div className="error-warpper">{errors.password}</div>
        ) : null}
      </div>
      <div className="form-group">
        <button type="submit" disabled={formik.isSubmitting} className="login-button btn btn-primary">ZALOGUJ</button>
      </div>
      <div className="form-group">
        <Link className="d-flex reset-pass-button" to={'/restartPassword'}>
          <span>RESETUJ HASŁO</span>
        </Link>
      </div>
    </form>);
  };

  return <div className="container-fluid login-form-wrapper d-flex justify-content-center align-items-center">
    <div className="login-form">
      <LoginForm />
    </div>
    <div className="powered-by">
      <a href="https://over-cloud.pl" target="_blank">
        <img alt="Powered By Over Cloud" className="img-fluid" src={logoCloud} />
      </a>
    </div>
  </div>
}

export default LoginComponent;
