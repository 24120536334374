import { logout } from '../actions/app.action';

class Api {

  constructor() {
    this.host = 'https://api-diet.overcloud.usermd.net/';
    // this.host = 'http://localhost:3001/';
    this.headers = {
      'Content-Type': 'application/json'
    }
  }

  getToken() {
    return localStorage.getItem('token') ? `JWT:${localStorage.getItem('token')}` : false;
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  getDispatch() {
    return this.dispatch;
  }

  async get(url, params, isFile) {
    if (params) {
      url += "?" + Object.keys(params).map(function (key) {
        return key + '=' + params[key];
      }).join('&');
    }

    return await this.request(this.host + url, 'GET', null, isFile);
  }

  async post(url, data) {
    return await this.request(this.host + url, 'POST', data);
  }

  async put(url, data, id) {
    if (id) {
      url += `/${id}`;
    }
    return await this.request(this.host + url, 'PUT', data);
  }

  async delete(url, id) {
    if (id) {
      url += `/${id}`;
    }
    return await this.request(this.host + url, 'DELETE', null);
  }

  async request(url, method, data, isFile = false) {

    if (this.getToken()) {
      this.headers.authorization = this.getToken();
    }

    let options = {
      method: method,
      headers: this.headers
    }

    if (isFile) {
      options.headers = { authorization: this.getToken() };
    }

    // dla POST, PUT
    if (data && Object.keys(data).length > 0) {
      options.body = JSON.stringify(data);
    }

    return await fetch(url, options).then((response) => {
      switch (response.status) {
        case 200:
        case 201:
        case 422:
        case 404:
        case 403:
          if (isFile) {
            return response.blob();
          } else {
            return response.json();
          }
        case 401:
          // bardzo ważny element, usuwamy token i zalogowanego uzytkownika ze stora jak jakiś request zwróci 401
          if (localStorage.getItem('token')) {
            this.getDispatch()(logout());
          }
          return response.json();
        default:
          return response.json();
      }

    }).then((data) => {
      return data;
    });

  }
}

export default new Api();