import React from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faBell } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/users.css';

moment.locale("pl");

function UserListCardComponent({ index, record }) {
  return <div className={`d-flex list-card-wrapper ${!record.active ? "no-active" : ""} flex-row ${record.isAdmin ? "is-admin" : ""} `}>
    <Link className="d-flex flex-fill" to={`/user/${record._id}`}>
      <div className="card-section card-label-wrapper justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Imie i Nazwisko</div>
        <div className="card-label-value">{record.name}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Data urodzin</div>
        <div className="card-label-value">{moment(record.birthDate).format('DD-MM-YYYY')}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Data utworzenia</div>
        <div className="card-label-value">{moment(record.createdAt).format('DD-MM-YYYY, h:mm:ss')}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">E-mail/Login</div>
        <div className="card-label-value">{record.login}</div>
      </div>
    </Link>
    <div className="card-section card-label-wrapper mr-0 justify-content-center align-items-end d-flex flex-fill flex-column">
      <Link to={{ pathname: '/diaries', state: { userId: record._id } }}>
        <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: '30px' }} color="#68bb73" />
      </Link>
    </div>
    <div className="card-section card-label-wrapper mr-0 justify-content-center align-items-end d-flex flex-fill flex-column">
      <Link to={{ pathname: '/notification', state: { userId: record._id } }}>
        <FontAwesomeIcon icon={faBell} style={{ fontSize: '30px' }} color="#68bb73" />
      </Link>
    </div>
  </div>
}

export default UserListCardComponent;