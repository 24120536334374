import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import DatePicker from 'react-date-picker';

import AppLoader from '../../application/components/loader.component';
import { useHistory, useParams } from "react-router-dom";

import * as Yup from 'yup';
import '../assets/css/user.css';

function UserComponent(props) {

    let { id } = useParams();
    let history = useHistory();

    const { getUser, clearUser, user, isLoading, voivodeships } = props;
    const formData = useCallback(setFormData, [user]);

    useEffect(() => {
        if (id) {
            getUser(id);
        }
        return () => {
            clearUser();
        }
    }, [id, getUser, clearUser,]);

    useEffect(() => { // jak przyjdzie użytkownk w propsach to ustawiamy go na formularzu
        formData();
    }, [user, formData]);

    function setFormData() {
        Object.keys(user).map((name) => {
            formik.setFieldValue(name, user[name]);
            return true;
        });
    }


    function handleDelete() {
        const { deleteUser } = props;
        let res = window.confirm("Czy chcesz usunąć użytkownika?");
        if (res == true) {
            deleteUser(id).then(() => {
                history.push('/users');
            });
        } else {
            return;
        }
    }

    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: {
            name: '',
            login: '',
            voivodeship: '',
            sex: '',
            active: true,
            isAdmin: false,
            birthDate: '',
            agreements: {
                regulationsAcceptance: false,
                privacyPolicyAcceptance: false
            },
            password: '',
            passwordConfirm: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Wartość jest wymagana"),
            login: Yup.string().required("Wartość jest wymagana"),
            voivodeship: Yup.object().shape(
                {
                    value: Yup.string(),
                    labe: Yup.string()
                }
            ),
            sex: Yup.string().required("Wartość jest wymagana"),
            active: Yup.string(),
            isAdmin: Yup.bool(),
            birthDate: Yup.date().required("Wartość jest wymagana"),
            agreements: Yup.object().shape(
                {
                    regulationsAcceptance: Yup.bool().oneOf([true], 'Polityka prywatności musi zostać zaakceptowana'),
                    privacyPolicyAcceptance: Yup.bool().oneOf([true], 'Polityka prywatności musi zostać zaakceptowana')
                }
            ),
            password: Yup.string(),
            passwordConfirm: Yup.string().when(['password'], {
                is: (password) => password && password.length > 0,
                then: Yup.string().required("Wartość jest wymagana").oneOf([Yup.ref('password'), null], 'Hasła do siebie nie pasują'),
                otherwise: Yup.string().oneOf([Yup.ref('password'), null], 'Hasła do siebie nie pasują'),
            }),
        }),
        onSubmit: (values) => {
            props.saveUser(values).then(() => {
                history.push('/users');
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });

    return <div className="user-wrapper flex-column d-flex flex-fill">
        {isLoading && <AppLoader type="absolute" />}
        <form onSubmit={formik.handleSubmit}>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>{id ? "Edycja" : "Dodawanie"} użytkownika</h4>
                <div className="save-button ">
                    <button type="submit" disabled={formik.isSubmitting} className="btn btn-primary">ZAPISZ</button>
                    {id && <button type="button" onClick={() => handleDelete()} className="btn btn-danger ml-2">USUŃ</button>}
                </div>
            </div>
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Imię i nazwisko</label>
                            <input
                                autoComplete="new-password"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.name && formik.errors.name) || (errors && errors.name) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                name="name"
                                id="name" />
                            {formik.errors.name && formik.touched.name ? (
                                <div className="error-warpper">{formik.errors.name}</div>
                            ) : null}
                            {errors && errors.name ? (
                                <div className="error-warpper">{errors.name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">E-mail / Login</label>
                            <input
                                autoComplete="new-password"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.login && formik.errors.login) || (errors && errors.login) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.login}
                                name="login"
                                id="login" />
                            {formik.errors.login && formik.touched.login ? (
                                <div className="error-warpper">{formik.errors.login}</div>
                            ) : null}
                            {errors && errors.login ? (
                                <div className="error-warpper">{errors.login}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Województwo</label>
                            <Select
                                placeholder="Wybierz"
                                name="voivodeship"
                                id="voivodeship"
                                value={formik.values.voivodeship}
                                onChange={(selectedVoivodeship) => formik.setFieldValue("voivodeship", selectedVoivodeship)}
                                options={voivodeships}
                                className={`basic-multi-select ${(formik.voivodeship && formik.touched.voivodeship && formik.errors.voivodeship) || (errors && errors.voivodeship) ? 'is-error' : ''}`}
                                classNamePrefix="select"
                            />
                            {/* <input
                                autoComplete="new-password"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.voivodeship && formik.errors.voivodeship) || (errors && errors.voivodeship) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.voivodeship}
                                name="voivodeship"
                                id="voivodeship"
                            /> */}
                            {formik.errors.voivodeship && formik.touched.voivodeship ? (
                                <div className="error-warpper">{formik.errors.voivodeship}</div>
                            ) : null}
                            {errors && errors.voivodeship ? (
                                <div className="error-warpper">{errors.voivodeship}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Hasło</label>
                            <input
                                autoComplete="new-password"
                                type="password"
                                className={`form-control ${(formik.errors && formik.touched.password && formik.errors.password) || (errors && errors.password) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                name="password"
                                id="password" />
                            {formik.errors.password && formik.touched.password ? (
                                <div className="error-warpper">{formik.errors.password}</div>
                            ) : null}
                            {errors && errors.password ? (
                                <div className="error-warpper">{errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Powtórz hasło</label>
                            <input
                                autoComplete="new-password"
                                type="password"
                                className={`form-control ${(formik.errors && formik.touched.passwordConfirm && formik.errors.passwordConfirm) || (errors && errors.passwordConfirm) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.passwordConfirm}
                                name="passwordConfirm"
                                id="passwordConfirm" />
                            {formik.errors.passwordConfirm && formik.touched.passwordConfirm ? (
                                <div className="error-warpper">{formik.errors.passwordConfirm}</div>
                            ) : null}
                            {errors && errors.passwordConfirm ? (
                                <div className="error-warpper">{errors.passwordConfirm}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Płeć</label>
                            <div className="d-flex flex-row">
                                <div className="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="female"
                                        value="female"
                                        onChange={formik.handleChange}
                                        checked={formik.values.sex === 'female'}
                                        name="sex"
                                    />
                                    <label className="custom-control-label" htmlFor="female">Kobieta</label>
                                </div>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="male"
                                        value="male"
                                        onChange={formik.handleChange}
                                        checked={formik.values.sex === 'male'}
                                        name="sex"
                                    />
                                    <label className="custom-control-label" htmlFor="male">Mężczyzna</label>
                                </div>
                            </div>
                            {formik.errors.sex && formik.touched.sex ? (
                                <div className="error-warpper mt-0">{formik.errors.sex}</div>
                            ) : null}
                            {errors && errors.sex ? (
                                <div className="error-warpper mt-0">{errors.sex}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3 col-lg-6">
                            <label htmlFor="birthDate">Data urodzenia</label>
                            <div className="row pl-3 lp24 pt-2 mb-2">
                                <DatePicker
                                    name="birthDate"
                                    id="birthDate"
                                    onChange={(value) => formik.setFieldValue("birthDate", value)}
                                    value={formik.values.birthDate}
                                />
                            </div>
                            {formik.errors.birthDate && formik.touched.birthDate ? (
                                <div className="error-warpper">{formik.errors.birthDate}</div>
                            ) : null}
                            {errors && errors.birthDate ? (
                                <div className="error-warpper">{errors.birthDate}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Aktywny</label>
                            <div className="d-flex flex-row">
                                <div className="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="active-true"
                                        value='true'
                                        onChange={() => formik.setFieldValue("active", true)}
                                        checked={formik.values.active === 'true' || formik.values.active === true}
                                        name="active"
                                    />
                                    <label className="custom-control-label" htmlFor="active-true">Tak</label>
                                </div>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="active-false"
                                        value='false'
                                        onChange={() => formik.setFieldValue("active", false)}
                                        checked={formik.values.active === 'false' || formik.values.active === false}
                                        name="active"
                                    />
                                    <label className="custom-control-label" htmlFor="active-false">Nie</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pl-3 row flex-column">
                        <div className="form-group custom-control custom-checkbox">
                            <input
                                id="regulationsAcceptance"
                                className="custom-control-input"
                                name="agreements.regulationsAcceptance"
                                type="checkbox"
                                onChange={formik.handleChange}
                                checked={formik.values.agreements.regulationsAcceptance}
                                value={formik.values.agreements.regulationsAcceptance}
                            />
                            <label className="custom-control-label" htmlFor="regulationsAcceptance">Regulamin</label>
                            {formik.errors.agreements && formik.errors.agreements.regulationsAcceptance && formik.touched.agreements && formik.touched.agreements.regulationsAcceptance ? (
                                <div className="d-flex error-warpper mt-0">{formik.errors.agreements.regulationsAcceptance}</div>
                            ) : null}
                            {errors && errors.agreements && errors.agreements.regulationsAcceptance ? (
                                <div className="d-flex error-warpper mt-0">{errors.agreements.regulationsAcceptance}</div>
                            ) : null}
                        </div>
                        <div className="form-group custom-control custom-checkbox">
                            <input
                                id="privacyPolicyAcceptance"
                                className="custom-control-input"
                                name="agreements.privacyPolicyAcceptance"
                                type="checkbox"
                                onChange={formik.handleChange}
                                checked={formik.values.agreements.privacyPolicyAcceptance}
                                value={formik.values.agreements.privacyPolicyAcceptance}
                            />
                            <label className="custom-control-label" htmlFor="privacyPolicyAcceptance">Polityka prywatności</label>
                            {formik.errors.agreements && formik.errors.agreements.privacyPolicyAcceptance && formik.touched.agreements && formik.touched.agreements.privacyPolicyAcceptance ? (
                                <div className="error-warpper mt-0">{formik.errors.agreements.privacyPolicyAcceptance}</div>
                            ) : null}
                            {errors && errors.agreements && errors.agreements.privacyPolicyAcceptance ? (
                                <div className="error-warpper mt-0">{errors.agreements.privacyPolicyAcceptance}</div>
                            ) : null}
                        </div>
                        {id ? <div className="form-group custom-control custom-checkbox">
                            <input
                                id="isAdmin"
                                className="custom-control-input"
                                name="isAdmin"
                                type="checkbox"
                                onChange={formik.handleChange}
                                checked={formik.values.isAdmin}
                                value={formik.values.isAdmin}
                            />
                            <label className="custom-control-label" htmlFor="isAdmin">Jestem administratorem</label>
                            {formik.errors.agreements && formik.errors.isAdmin && formik.touched.agreements && formik.touched.isAdmin ? (
                                <div className="error-warpper mt-0">{formik.errors.isAdmin}</div>
                            ) : null}
                            {errors && errors.agreements && errors.agreements.isAdmin ? (
                                <div className="error-warpper mt-0">{errors.isAdmin}</div>
                            ) : null}
                        </div> :null}
                    </div>
                </div>
            </div>
        </form>
    </div>
}

export default UserComponent;